<template>
    <div>
        <LazySidebar :open="open">
            <div class="">
                <div class="bg-white shadow flex flex-row items-center justify-between py-4 px-8">
                    <div class="text-lg font-semibold text-gray-700">
                        Create New Team
                    </div>
                    <div class="cursor-pointer" @click.stop="closeTeamSidebar">
                        <svg xmlns="http://www.w3.org/2000/svg" class="text-gray-600 hover:text-gray-800 h-5 w-5"
                            viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd"
                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                clip-rule="evenodd" />
                        </svg>
                    </div>
                </div>
                <div class="py-12 px-8 space-y-8 divide-y divide-gray-200">
                    <div class="space-y-8">
                        <div>
                            <div>
                                <h4 class="text-lg leading-6 font-medium text-gray-900">
                                    Team Name <span class="text-red-400">*</span>
                                </h4>
                            </div>
                            <div class="mt-5">
                                <div>
                                    <input autocomplete="off" data-lpignore="true"  v-model="teamName" type="text" name="firstName" id="firstName"
                                        class="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                        placeholder="">
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-row justify-end py-4">
                            <div></div>
                            <button type="button" @click.stop="closeTeamSidebar"
                                class="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mr-4">
                                Cancel
                            </button>
                            <button v-show="!loadingNewTeam" @click.stop="createTeam" type="submit"
                                class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-5 font-medium rounded-md shadow-sm text-white bg-emerald-500 hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
                                Create
                            </button>
                            <div v-show="loadingNewTeam" type="submit" class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-5 font-medium rounded-md shadow-sm text-white bg-emerald-500 hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
                                <SpinnersThreeDotsWhite />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LazySidebar>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import moment from 'moment-timezone';
import { formatUtcOffset, getTimeZoneGroups, getTimeZoneInfo } from "~/data/timezone"
import { toLower } from 'lodash'
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

const props = defineProps({
    value: {
      type: String,
      default: 'Select',
    },
    open: {
        type: Boolean,
        default: false
    },
})
const teamName = ref("")
const store = useGlobalStore();
const emit = defineEmits(["closeTeamSidebar"])
const loadingNewTeam = ref(false)
let now = Date.now()
let timezoneOptions = [];

const closeTeamSidebar = () => {
    emit("closeTeamSidebar")
}

const timezones = computed(() => {
  const tzs = [];
  let timezoneEntities = timeZoneGroups.value;
  for (let i = 0; i < timezoneEntities.length; i++) {
    for (let j=0; j< timezoneEntities[i].options.length; j++){
      tzs.push(timezoneEntities[i].options[j])
    }
  }

  timezoneOptions = tzs;
  return tzs;
});

const timeZoneGroups = computed(() => {
  return getTimeZoneGroups(false).map((group) => {
    const options = group.zones.reduce((options, zone) => {
      const info = getTimeZoneInfo(zone, now);

      if (!info) {
        return options;
      }

      let country = { code: '', name: '' }
      if (info.countries.length > 0) country = info.countries[0]

      options.push({
        label: info.name,
        value: info.zone,
        country,
        abbreviation: info.abbreviation,
        searchIndex: getSearchIndex(info, now),
        utc: formatUtcOffset(now, info.zone)
      });

      return options;
    }, []);

    return {
      label: group.name,
      options,
    }
  })
});

const getSearchIndex = (info, timestamp) => {
  const parts = [
    toLower(info.name),
    toLower(info.abbreviation),
    toLower(formatUtcOffset(timestamp, info.zone)),
  ];

  for (const country of info.countries) {
    parts.push(toLower(country.name));
    parts.push(toLower(country.code));
  }

  return parts.join('|');
};

const getCurrentTimezone = computed(() => {
  const offsetMinutes = moment().utcOffset();
  const offsetHours = offsetMinutes / 60;
  const sign = offsetMinutes >= 0 ? '+' : '-';
  const formattedOffset = `UTC${sign}${Math.abs(offsetHours).toFixed(1)}`;

  for (let i = 0; i < timezones.value.length; i++) {
    const opt = timezones.value[i];
    if (opt.utc === formattedOffset) {
      return opt.label
    }
  }

  return "Atlantic/Reykjavik"
})

const createTeam = async () => {
    if (teamName.value.trim().length === 0) {
        toast('Team name cannot be empty...', {
            "theme": "auto",
            "type": "warning",
            "hideProgressBar": false,
            "autoClose": 3000,
            "transition": "slide",
        })
    } else {
        try {
            loadingNewTeam.value = true
            const res = await store.createTeam({ name: teamName.value, timezone: getCurrentTimezone.value });
            toast('Team has been created successfully', {
                "theme": "auto",
                "type": "success",
                "hideProgressBar": false,
                "autoClose": 3000,
                "transition": "slide",
            })
            teamName.value = "";
            closeTeamSidebar();
        } catch (err) {
            console.log("An error occurred while creating a team : ", err)
            toast('An error occurred while creating a team :(', {
                "theme": "auto",
                "type": "error",
                "hideProgressBar": false,
                "autoClose": 3000,
                "transition": "slide",
            })
        } finally {
            loadingNewTeam.value = false
        }
    }
}

</script>

<style scoped></style>
    